<template>
  <div class="ion-padding-horizontal">
    <ion-text color="dark">
      <p>Da giovedì 3 novembre 2022 e fino a giovedì 24 novembre 2022 , tutti potranno esprimere la propria preferenza tramite l'App di PA Social.</p>
    </ion-text>
  </div>
</template>


<script lang="ts">
import {
  IonText
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
    IonText
	},
    setup() {
        return {}
    }
});
</script>

<style scoped>

</style>
